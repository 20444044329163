import {
  ApolloClient,
  DefaultContext,
  FetchPolicy,
  MutationFunctionOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { Either, left, right } from 'fp-ts/lib/Either';
import { DocumentNode, ExecutionResult } from 'graphql';

export const createGraphqlMutationEither = async <Result, Variable>(
  mutation: (
    options?: MutationFunctionOptions<Result, Variable>
  ) => Promise<ExecutionResult<Result>>,
  options: MutationFunctionOptions<Result, Variable>
): Promise<Either<any, Result>> => {
  try {
    const { data } = await mutation(options);
    return right(data);
  } catch (err) {
    return left(err);
  }
};

/**
 *
 * @param args.retries The number of times to retry the API call if it fails. Defaults to 0 (no retries).
 */
export const tryQuery = async <TData>(args: {
  apolloClient: ApolloClient<any>;
  query: DocumentNode | TypedDocumentNode<TData>;
  variables?: any;
  context?: DefaultContext;
  fetchPolicy?: FetchPolicy;
  retries?: number;
}): Promise<{ response?: TData; error?: any }> => {
  let retriesLeft = args.retries ?? 0;
  let error: any = undefined;
  try {
    const result = await args.apolloClient.query({
      query: args.query,
      variables: args.variables,
      fetchPolicy: args.fetchPolicy,
      context: args.context,
    });

    if (!result.error) {
      return { response: result.data };
    }
    error = result.error;
  } catch (exception) {
    error = exception;
  }

  --retriesLeft;
  if (retriesLeft > 0) {
    // Wait 2.5 secs to try again
    await new Promise((f) => setTimeout(f, 2500));
    return tryQuery({
      apolloClient: args.apolloClient,
      query: args.query,
      variables: args.variables,
      context: args.context,
      fetchPolicy: args.fetchPolicy,
      retries: retriesLeft,
    });
  }
  return { error };
};
