import { createAction } from 'redux-actions';

export const GTMActions = {
  LOGIN_WITH_EMAIL_FAILURE: 'glints/GoogleTagManager/LOGIN_WITH_EMAIL_FAILURE',
  LOGIN_WITH_FACEBOOK_FAILED:
    'glints/GoogleTagManager/LOGIN_WITH_FACEBOOK_FAILED',
  LOGIN_WITH_LINKEDIN_FAILED:
    'glints/GoogleTagManager/LOGIN_WITH_LINKEDIN_FAILED',
  LOGIN_SIGN_UP_WITH_GOOGLE_CLICKED:
    'glints/GoogleTagManager/LOGIN_SIGN_UP_WITH_GOOGLE_CLICKED',
  LOGIN_WITH_GOOGLE_FAILED: 'glints/GoogleTagManager/LOGIN_WITH_GOOGLE_FAILED',
  LOGOUT_SUCCESS: 'glints/GoogleTagManager/LOGOUT_SUCCESS',
  LOGIN_BUTTON_CLICKED: 'glints/GoogleTagManager/LOGIN_BUTTON_CLICKED',
  SIGNUP_BUTTON_CLICKED: 'glints/GoogleTagManager/SIGNUP_BUTTON_CLICKED',
  GOOGLE_ONE_TAP_VIEWED: 'glints/GoogleTagManager/GOOGLE_ONE_TAP_VIEWED',
};

export const LoginWithEmailFailure = createAction(
  GTMActions.LOGIN_WITH_EMAIL_FAILURE
);

export const LoginWithFacebookFailed = createAction(
  GTMActions.LOGIN_WITH_FACEBOOK_FAILED
);
export const LoginWithLinkedinFailed = createAction(
  GTMActions.LOGIN_WITH_LINKEDIN_FAILED
);
export const LoginWithGoogleFailed = createAction(
  GTMActions.LOGIN_WITH_GOOGLE_FAILED
);
export const LoginSignUpWithGoogleClicked = createAction(
  GTMActions.LOGIN_SIGN_UP_WITH_GOOGLE_CLICKED
);

export const LogoutSuccess = createAction(GTMActions.LOGOUT_SUCCESS);

export const LoginButtonClicked = createAction(GTMActions.LOGIN_BUTTON_CLICKED);
export const SignUpButtonClicked = createAction(
  GTMActions.SIGNUP_BUTTON_CLICKED
);

export const GoogleOneTapViewed = createAction(
  GTMActions.GOOGLE_ONE_TAP_VIEWED
);
