import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Reducer,
} from 'redux';
import thunk from 'redux-thunk';

import { makeAxios } from 'src/common/axios';
import {
  AsyncReducerKeys,
  AsyncReducersMap,
  DstStore,
  State,
} from 'src/global/store';
import api from 'src/middleware/api';
import googleTagManager from 'src/middleware/GoogleTagManager';
import { createRootReducer } from 'src/staticReducers';

const asyncReducersMap: AsyncReducersMap = {};

const createInjectReducers =
  (store: DstStore) => (reducersMap: AsyncReducersMap) => {
    let isInjected = true;
    Object.entries(reducersMap).forEach(
      ([key, reducer]: [keyof AsyncReducersMap, Reducer]) => {
        if (asyncReducersMap[key]) {
          return;
        }
        asyncReducersMap[key] = reducer;
        isInjected = false;
      }
    );

    if (isInjected) {
      return;
    }

    const newRootReducer = createRootReducer(asyncReducersMap);
    store.replaceReducer(newRootReducer);
  };

/**
 * Initializes an instance of the Redux store (client only).
 *
 * @param initialState The initial state to initialize the store with.
 */
export function makeClientStore(
  initialState: Partial<State> = {},
  initialAsyncReducerKeys?: AsyncReducerKeys
): DstStore {
  const config = initialState.clientConfig || {};

  const middlewares = [
    applyMiddleware(
      thunk.withExtraArgument({
        api: makeAxios(config),
      })
    ),
    applyMiddleware(api as Middleware),
    applyMiddleware(googleTagManager),
  ];

  if (config.DEBUG) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createLogger } = require('redux-logger');
    middlewares.push(applyMiddleware(createLogger({ collapsed: true })));
  }

  // asyncReducerPlaceholdersMap is for reserving the initial async state,
  // the WithReducer HOC will then replace it with the actual async reducers map.
  // This way, we don't need to create another async reducers map to initialize client store,
  // we reuse the one in the WithReducer HOC.
  const asyncReducerPlaceholdersMap = initialAsyncReducerKeys
    ? initialAsyncReducerKeys.reduce((acc, curr) => {
        return { ...acc, [curr]: (state = {}) => state };
      }, {})
    : {};

  const composeWithDevTools = config.DEBUG
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

  const store: DstStore = createStore(
    createRootReducer(asyncReducerPlaceholdersMap),
    initialState as State,
    composeWithDevTools(...middlewares)
  );
  store.injectReducers = createInjectReducers(store);

  return store;
}

/**
 * Initializes an instance of the Redux store (server only).
 *
 * @param initialState The initial state to initialize the store with.
 */
export function makeServerStore(
  initialState: Partial<State> = {},
  headers?: StrIndexObject
): DstStore {
  const config = initialState.clientConfig || {};
  const middlewares = [
    applyMiddleware(
      thunk.withExtraArgument({
        // We use axios for now due to the constraint of unable to pass Hapi.Server down from getInitialProps.
        api: makeAxios(config, headers),
      })
    ),
    applyMiddleware(api as Middleware),
  ];

  const store: DstStore = createStore(
    createRootReducer(asyncReducersMap),
    initialState as State,
    compose(...middlewares)
  );

  store.injectReducers = createInjectReducers(store);

  return store;
}
