import { createAction } from 'redux-actions';

import { ReduxThunkAction } from 'src/global/store';
import { JobRolePreferencesState } from 'src/reducers/user/jobRolePreferences';

export const Actions = {
  REQUEST_JOB_ROLE_PREFERENCES:
    'glints/user/jobRolePreferences/REQUEST_JOB_ROLE_PREFERENCES',
  RECEIVE_JOB_ROLE_PREFERENCES:
    'glints/user/jobRolePreferences/RECEIVE_JOB_ROLE_PREFERENCES',
  CLEAR_JOB_ROLE_PREFERENCES:
    'glints/user/jobRolePreferences/CLEAR_JOB_ROLE_PREFERENCES',
};

export const requestJobRolePreferences = createAction(
  Actions.REQUEST_JOB_ROLE_PREFERENCES
);
export const receiveJobRolePreferences = createAction(
  Actions.RECEIVE_JOB_ROLE_PREFERENCES
);
export const clearJobRolePreferences = createAction(
  Actions.CLEAR_JOB_ROLE_PREFERENCES
);

export const updateJobRolePreferencesFromGraphQL = (
  data: JobRolePreferencesState
): ReduxThunkAction<any> => {
  return async (dispatch) => {
    try {
      dispatch(receiveJobRolePreferences(data));
    } catch (err) {
      dispatch(receiveJobRolePreferences(data.error));
      throw err;
    }
  };
};
