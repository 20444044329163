import { isEmpty } from 'lodash';
import { encode, ParsedUrlQuery } from 'querystring';

import { generateSlug } from 'src/common/generateSlug';
import {
  ID_JOB_EXPLORE_URL,
  JOB_EXPLORE_URL,
  ROUTES,
  VN_JOB_EXPLORE_URL,
} from 'src/common/routes';
import { JobLinkProps } from 'src/common/types/jobLinkProps';
import { Company } from 'src/global/models/Company';
import { Event } from 'src/global/models/Event';
import { Job } from 'src/global/models/Job';
import { UTM_REFERRER } from 'src/modules/Opportunities/constants';
import { createUrlSlug } from 'src/modules/Opportunities/utils/createUrlSlug';

import {
  JOB_TITLE,
  SHARE_ID,
  TRACE_INFO,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_REFERRER_PARAM,
  UTM_SOURCE,
} from '../constants';
import { CountryCodes } from '../enums';

export type QueryParams = {
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmReferrer?: string | null;
  utmSource?: string | null;
  shareId?: string | null;
  traceInfo?: string | null;
};

export const UTM_PARAMS = [
  'utm_referrer',
  'utm_medium',
  'utm_campaign',
  'utm_source',
];

export const generateQueryParams = (params: QueryParams): string => {
  const queryParams: string[] = [];

  const addQueryParam = (key: string, value?: string | null) => {
    if (value) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  };

  addQueryParam(UTM_MEDIUM, params.utmMedium);
  addQueryParam(UTM_CAMPAIGN, params.utmCampaign);
  addQueryParam(UTM_REFERRER_PARAM, params.utmReferrer);
  addQueryParam(UTM_SOURCE, params.utmSource);

  addQueryParam(SHARE_ID, params.shareId);
  addQueryParam(TRACE_INFO, params.traceInfo);

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
};

export const getJobWithCountryFilterURL = (
  country: CountryCodes,
  canApplyCountriesQuery: boolean
) => {
  const isID = country === CountryCodes.ID;
  const isVN = country === CountryCodes.VN;

  return isID
    ? ID_JOB_EXPLORE_URL
    : isVN
    ? VN_JOB_EXPLORE_URL
    : country && canApplyCountriesQuery
    ? JOB_EXPLORE_URL
    : `/${ROUTES.opportunitiesJobsExplore}`;
};

export const getJobWithCompanyFilterURL = (
  companyId: string,
  companyName: string,
  country?: CountryCodes,
  language?: string,
  query?: string
) => {
  return companyId
    ? `/company/${createUrlSlug({
        slug: generateSlug(companyName),
        countryCode: country,
        language,
      })}/${companyId}?${query}`
    : `/${ROUTES.opportunitiesJobsExplore}?${query}`;
};

/**
 * Given a company, generate and return the props for Link.
 *
 * @param company Company object
 */
export interface CompanyLinkProps {
  href: {
    pathname: string;
    query: {
      id?: string;
      companyName?: string;
      utm_referrer?: string | null;
      utm_campaign?: string | null;
      utm_medium?: string | null;
      utm_source?: string | null;
    };
  };
  as: string;
}

export const getCompanyLinkProps = (
  company: Company,
  currentURLSearchParams?: URLSearchParams
): CompanyLinkProps => {
  if (isEmpty(company)) {
    return {
      href: {
        pathname: '',
        query: {
          id: undefined,
          companyName: undefined,
          utm_referrer: undefined,
          utm_campaign: undefined,
          utm_medium: undefined,
          utm_source: undefined,
        },
      },
      as: '',
    };
  }

  const utmReferrer = currentURLSearchParams?.get(UTM_REFERRER_PARAM);
  const utmMedium = currentURLSearchParams?.get(UTM_MEDIUM);
  const utmCampaign = currentURLSearchParams?.get(UTM_CAMPAIGN);
  const utmSource = currentURLSearchParams?.get(UTM_SOURCE);

  const queryParams = generateQueryParams({
    utmMedium,
    utmCampaign,
    utmReferrer,
    utmSource,
  });

  const id = company.id;
  const companyName = generateSlug(company.name);
  const as = companyName
    ? `/${ROUTES.companies}/${companyName}/${id}${queryParams}`
    : `/${ROUTES.companies}/${id}${queryParams}`;

  return {
    href: {
      pathname: `/${ROUTES.company}`,
      query: {
        id,
        companyName,
        utm_referrer: utmReferrer,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_source: utmSource,
      },
    },
    as,
  };
};

export const getJobLinkProps = (
  opportunity: Partial<Job>,
  referrer?: UTM_REFERRER,
  currentURLSearchParams?: URLSearchParams
): JobLinkProps => {
  const id = opportunity.id;
  const title = generateSlug(opportunity.title);
  const traceInfo = opportunity.traceInfo;

  const utmReferrer =
    currentURLSearchParams?.get(UTM_REFERRER_PARAM) ?? referrer;
  const utmMedium = currentURLSearchParams?.get(UTM_MEDIUM);
  const utmCampaign = currentURLSearchParams?.get(UTM_CAMPAIGN);
  const utmSource = currentURLSearchParams?.get(UTM_SOURCE);

  const shareId = currentURLSearchParams?.get(SHARE_ID);
  const selectedTraceInfo =
    currentURLSearchParams?.get(TRACE_INFO) ?? traceInfo;

  const queryParams = generateQueryParams({
    utmMedium,
    utmCampaign,
    utmReferrer: referrer ?? utmReferrer,
    utmSource,
    shareId,
    traceInfo: selectedTraceInfo,
  });

  return {
    href: {
      pathname: `/${ROUTES.opportunitiesJob}`,
      query: {
        id,
        title,
        utm_referrer: referrer ?? utmReferrer,
        utm_campaign: utmCampaign && utmCampaign,
        utm_medium: utmMedium && utmMedium,
        utm_source: utmSource && utmSource,
        traceInfo: selectedTraceInfo && selectedTraceInfo,
        shareId: shareId && shareId,
      },
    },
    as: `/${ROUTES.opportunitiesJobs}/${title}/${id}${queryParams}`,
  };
};

export const getJobLinkPropsFromAsPath = (asPath: string) => {
  const urlParams = new URLSearchParams(asPath.split('?')[1]);

  const utmReferrer = urlParams.get(UTM_REFERRER_PARAM);
  const utmMedium = urlParams.get(UTM_MEDIUM);
  const utmCampaign = urlParams.get(UTM_CAMPAIGN);
  const utmSource = urlParams.get(UTM_SOURCE);

  const shareId = urlParams.get(SHARE_ID);
  const traceInfo = urlParams.get(TRACE_INFO);

  const jobTitle = urlParams.get(JOB_TITLE);
  const jobId = urlParams.get('id');

  const query = generateQueryParams({
    utmMedium,
    utmCampaign,
    utmReferrer,
    utmSource,
    shareId,
    traceInfo,
  });

  return {
    href: {
      pathname: `/${ROUTES.opportunitiesJob}`,
      query: {
        id: jobId,
        title: jobTitle,
        utm_referrer: utmReferrer && utmReferrer,
        utm_campaign: utmCampaign && utmCampaign,
        utm_medium: utmMedium && utmMedium,
        utm_source: utmSource && utmSource,
        traceInfo: traceInfo && traceInfo,
        shareId: shareId && shareId,
      },
    },
    as: `/${ROUTES.opportunitiesJobs}/${jobTitle}/${jobId}${query}`,
  };
};

export const getApplyJobLinkProps = (
  opportunity: Partial<Job>,
  queryParam: ParsedUrlQuery
) => {
  const urlQueryString = new URLSearchParams(encode(queryParam));
  const props = getJobLinkProps(opportunity, undefined, urlQueryString);
  const as = props.as.replace('?', '/apply?');

  return {
    as: as,
    href: props.href,
    query: queryParam,
  };
};

export const getExpertClassLinkProps = (expertClass: Partial<Event>) => {
  const titleSlug = generateSlug(expertClass.title);
  return {
    href: `/${ROUTES.expertClassDetail}?title=${titleSlug}&shortId=${expertClass.shortId}`,
    as: `/${ROUTES.expertClass}/${titleSlug}/${expertClass.shortId}`,
  };
};

export const getExpertClassOrderLinkProps = (order: { id: string }) => {
  const { id } = order;

  return {
    href: {
      pathname: `/${ROUTES.expertClassOrders}/id`,
      query: {
        id,
      },
    },
    as: `/${ROUTES.expertClassOrders}/${id}`,
  };
};

export const getExpertClassConfirmOrderPageUrl = (expertClassShortId: string) =>
  `/${ROUTES.confirmOrder}?expertClassId=${expertClassShortId}`;

export const getUtmParams = (
  currentParams: ParsedUrlQuery
): Record<string, string> => {
  return Object.entries(currentParams)
    .filter(([key]) => UTM_PARAMS.includes(key))
    .reduce(
      (acc, [key, value]) => {
        if (typeof value === 'string') {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, string>
    );
};

export const getUtmParamsFromURL = (url: string) => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const utmData: Record<string, string> = {};

  UTM_PARAMS.forEach((param) => {
    if (searchParams.has(param)) {
      utmData[param] = searchParams.get(param) as string;
    }
  });

  return utmData;
};

export const getURLSearchParamsFromQuery = (queries: ParsedUrlQuery) => {
  const marketingQueryUtmParams = getUtmParams(queries);
  return new URLSearchParams(encode(marketingQueryUtmParams));
};

export const generateURLWithQueries = (
  to: string,
  currentParams?: ParsedUrlQuery
) => {
  if (!currentParams) return to;

  try {
    const [pathname, existingQuery] = to.split('?');

    const queryParams = new URLSearchParams(existingQuery || '');
    const utmParams = getUtmParams(currentParams);

    Object.entries(utmParams).forEach(([key, value]) => {
      if (!queryParams.has(key) && value && value.trim() !== '') {
        queryParams.set(key, value);
      }
    });

    const queryString = queryParams.toString();
    return queryString ? `${pathname}?${queryString}` : pathname;
  } catch (error) {
    console.error('Error processing URL:', error);
    return to;
  }
};
