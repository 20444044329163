import { Children, cloneElement, memo, useMemo } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { useRouter } from 'next/router';
import { format, UrlObject } from 'url';

import useConfig from 'src/common/hooks/useConfig';
import { generateURLWithQueries, getUtmParams } from 'src/common/links';

import { enhanceHrefWithUtmParams } from './helpers';

function formatURL(url: string | UrlObject) {
  return url && typeof url === 'object' ? format(url) : (url as string);
}

const Link = memo(
  ({ children, ...rest }: React.PropsWithChildren<NextLinkProps>) => {
    const config = useConfig();
    const router = useRouter();
    const queries = router.query;
    const marketingQueryParams = getUtmParams(queries);

    const pathPrefix = config.BASENAME;
    const child = Children.only(children) as React.ReactElement<any>;
    const shouldPassHref =
      rest.passHref || (child.type === 'a' && !('href' in child.props));

    // Enhance href with UTM params if they exist
    const enhancedRest = useMemo(() => {
      const enhanced = { ...rest };
      if (
        marketingQueryParams &&
        Object.keys(marketingQueryParams).length > 0
      ) {
        enhanced.href = enhanceHrefWithUtmParams(
          rest.href,
          marketingQueryParams
        );
        if (enhanced.as) {
          enhanced.as = generateURLWithQueries(formatURL(enhanced.as), queries);
        }
      }
      return enhanced;
    }, [marketingQueryParams, queries, rest]);

    // bypass, nothing to do here
    if (!pathPrefix || pathPrefix === '/' || !shouldPassHref) {
      return <NextLink {...enhancedRest}>{child}</NextLink>;
    }

    // prepend pathPrefix to the child's props.href
    const props: { href?: string } = {};
    const href = formatURL(enhancedRest.as || enhancedRest.href);

    if (href && !href.includes(pathPrefix)) {
      if (href === '/') props.href = pathPrefix;
      else props.href = pathPrefix + href;
    }

    return (
      <NextLink {...enhancedRest} passHref={false}>
        {cloneElement(child, props)}
      </NextLink>
    );
  }
);

Link.displayName = 'Link';

export type LinkProps = NextLinkProps;

export default Link;
