import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { getClassName } from 'src/common/ComponentHelpers';
import useConfig from 'src/common/hooks/useConfig';
import { generateURLWithQueries } from 'src/common/links';
import { NewFeatureBadge } from 'src/components/NewFeatureBadge';

import * as S from './NavigationComponents.sc';
import { MenuBaseItemType } from './types';

interface NavigationItemsProps {
  handleCloseDrawer: () => void;
  menuItems?: MenuBaseItemType[];
}

export const NavigationItems: React.FC<NavigationItemsProps> = ({
  handleCloseDrawer,
  menuItems,
}) => {
  const config = useConfig();
  const country = config?.COUNTRY;
  const router = useRouter();
  const query = router.query;

  return (
    <>
      {menuItems?.flat().map((item, i) => {
        const href = item?.to
          ? generateURLWithQueries(item.to, query)
          : undefined;

        return (
          <Choose key={item}>
            <When condition={item?.external}>
              {!country && (i === 1 || i === 3) && <S.Divider />}
              {!country && i === 1 && (
                <S.SectionTitleWrapper>Hiring solutions</S.SectionTitleWrapper>
              )}
              <a
                href={href}
                target="_blank"
                key={item?.label}
                className={getClassName(item?.label)}
                rel="noreferrer"
              >
                <S.TextWrapper>
                  <Choose>
                    <When condition={!country}>
                      <S.LinkWrapper>
                        <FormattedMessage {...item?.message} />
                        <S.ArrowRight />
                      </S.LinkWrapper>
                    </When>
                    <Otherwise>
                      <S.UpperCaseWrapper
                        role="button"
                        aria-label={`${item?.label} page`}
                      >
                        <FormattedMessage {...item?.message} />
                      </S.UpperCaseWrapper>
                    </Otherwise>
                  </Choose>
                  {item?.new && (
                    <S.NewFeatureBadgeContainer>
                      <NewFeatureBadge />
                    </S.NewFeatureBadgeContainer>
                  )}
                </S.TextWrapper>
              </a>
            </When>
            <Otherwise>
              <Link
                key={href}
                href={href ?? ''}
                as={href || item?.as}
                prefetch={false}
              >
                <a
                  target={item?.target}
                  onClick={handleCloseDrawer}
                  className={getClassName(item?.label)}
                >
                  <S.TextWrapper
                    role="button"
                    aria-label={`${item?.label} page`}
                  >
                    <S.UpperCaseWrapper>
                      <FormattedMessage {...item?.message} />
                    </S.UpperCaseWrapper>
                    {item?.new && (
                      <S.NewFeatureBadgeContainer>
                        <NewFeatureBadge />
                      </S.NewFeatureBadgeContainer>
                    )}
                  </S.TextWrapper>
                </a>
              </Link>
            </Otherwise>
          </Choose>
        );
      })}
    </>
  );
};
