import { RawDraftContentState } from 'draft-js';
import { ParsedUrlQuery } from 'querystring';
import { Message } from 'react-intl';

import { CountryCodes } from 'src/common/enums';
import {
  ExperienceValueLabel,
  JobsSortByKey,
  LastUpdated,
} from 'src/common/enums/jobs';
import { LocationFaqData } from 'src/modules/Opportunities/pages/Explore/Components/TextualContent/types';
import { SearchJobsQueryData } from 'src/modules/Opportunities/pages/Explore/graphqlUtils';
import { SlugTypes } from 'src/modules/Opportunities/utils/slugUrlParams';
import { JobInterface } from 'src/modules/Opportunity/interface';
import { HierarchicalJobCategory } from 'src/modules/Profile/graphql/hierarchicalJobCategories';

import { JobType, WorkArrangementOption } from '../../../../global/models/Job';
import { HierarchicalLocation } from '../../../Profile/graphql/hierarchicalLocations';
import { JobSearchFiltersResponse } from './Components/FilterSection/types';

// graphql searchJobs query keys
export const SearchJobsQueryKey = {
  Keyword: 'SearchTerm',
  Country: 'CountryCode',
  SortBy: 'sortBy',
  LastUpdated: 'lastUpdatedAtRange',
  WorkArrangementOptions: 'workArrangementOptions',
  JobType: 'type',
  Cities: 'CityId',
  LocationIds: 'LocationIds',
  CitySubDivisions: 'CitySubDivisionId',
  Experience: 'yearsOfExperienceFilter',
  HierarchicalJobCategoryIds: 'HierarchicalJobCategoryIds',
  HierarchicalJobCategoryIdLevel3: 'HierarchicalJobCategoryIdLevel3',
  Companies: 'CompanyId',
  Salary: 'SalaryFilter',
  JobRole: 'JobTitles',
  EducationLevel: 'educationLevels',
} as const;

// graphql jobSearchFilters query keys
export const JobSearchFiltersQueryKey = {
  SearchKeyword: 'searchTerms',
  SearchCountry: 'searchCountryCode',
  SearchCity: 'searchCityId',
  SearchCitySubdivision: 'searchCitySubDivisionId',
  FilterCities: 'filterCityIds',
  FilterLocationIds: 'locationIds',
  FilterTypes: 'types',
  FilterCompanies: 'companyIds',
  FilterSalary: 'salaryFilter',
  FilterExperience: 'yearsOfExperienceFilter',
  FilterWorkArrangementOptions: 'workArrangementOptionsFilter',
  FilterLastUpdated: 'lastUpdatedAtRange',
} as const;

export type YearsOfExperienceFilterInput = {
  ranges: ExperienceValueLabel[];
};

export type WorkArrangementOptionsFilterInput = {
  options: WorkArrangementOption[];
  hasRemoteJobsOnly: boolean;
};

export type SalaryFilterInput = {
  minimum: number;
  currency: string;
};

export const FilterRouterQueryKey = {
  SortBy: 'sortBy',
  LastUpdated: 'lastUpdated',
  WorkArrangementOptions: 'workArrangementOptions',
  JobType: 'jobTypes',
  Cities: 'cities',
  FilterLocationIds: 'filterLocationIds',
  CitySubDivisions: 'citySubDivisions',
  JobRoles: 'jobRoles',
  Companies: 'companies',
  HierarchicalJobCategoryIds: 'HierarchicalJobCategoryIds',
  // for SearchJobsQueryKey.Experience
  YearsOfExperienceRanges: 'yearsOfExperienceRanges',
  // for SearchJobsQueryKey.Salary
  MinSalary: 'minSalary',
  Currency: 'currency',
  EducationLevel: 'educationLevel',
} as const;

export type FilterRouterQueryKeyValue =
  (typeof FilterRouterQueryKey)[keyof typeof FilterRouterQueryKey];

export type FilterRouterQuery = {
  [FilterRouterQueryKey.SortBy]?: JobsSortByKey;
  [FilterRouterQueryKey.LastUpdated]?: LastUpdated;
  [FilterRouterQueryKey.WorkArrangementOptions]?: string;
  [FilterRouterQueryKey.JobType]?: string;
  [FilterRouterQueryKey.Cities]?: string;
  [FilterRouterQueryKey.CitySubDivisions]?: string;
  [FilterRouterQueryKey.Companies]?: string;
  [FilterRouterQueryKey.JobRoles]?: string;
  [FilterRouterQueryKey.YearsOfExperienceRanges]?: string;
  [FilterRouterQueryKey.MinSalary]?: string;
  [FilterRouterQueryKey.Currency]?: string;
  [FilterRouterQueryKey.FilterLocationIds]?: string;
  [FilterRouterQueryKey.HierarchicalJobCategoryIds]?: string;
  [FilterRouterQueryKey.EducationLevel]?: string;
};

export const SearchRouterQueryKey = {
  Keyword: 'keyword',
  CitySubDivision: 'searchCitySubDivision',
  Country: 'country',
  City: 'searchCity',
  LocationId: 'locationId',
  Location: 'locationName',
  SortBy: 'sortBy',
  LowestLocationlevel: 'lowestLocationLevel',
} as const;

export type SearchRouterQuery = {
  [SearchRouterQueryKey.Keyword]?: string;
  [SearchRouterQueryKey.Country]?: string;
  [SearchRouterQueryKey.City]?: string;
  [SearchRouterQueryKey.CitySubDivision]?: string;
  [SearchRouterQueryKey.Location]?: string;
  [SearchRouterQueryKey.LocationId]?: string;
  [SearchRouterQueryKey.LowestLocationlevel]?: string;
};

export const PaginationRouterQueryKey = {
  Page: 'page',
} as const;

export type PaginationRouterQuery = {
  [PaginationRouterQueryKey.Page]?: string;
};

export type RouterQuery = SearchRouterQuery &
  FilterRouterQuery &
  PaginationRouterQuery;

export type SEOSlugQuery = {
  companies?: string;
  cities?: string;
  jobCategories?: string;
  country?: string;
  jobRole?: string;
  locationId?: string;
  categoryId?: string;

  // Custom Slug Pages Queries
  Keyword?: string;
  CountryCode?: string;
  workArrangementOptions?: WorkArrangementOption[];
  type?: JobType[];
  cityIds?: string[];
  locationIds?: string[];
  categoryIds?: string[];
  yearsOfExperiences?: YearsOfExperienceFilterInput;
  JobTitles?: number[];
  locationName?: string;
  categoryName?: string;
};

export type SEOQueryFunctionInputs = {
  countryCode: CountryCodes;
  type: SlugTypes;
  slugQuery: SEOSlugQuery;
  freeQueryKeyword: string;
};

export enum JobSearchImproveVariant {
  CURRENT = 'CURRENT',
  VARIANT_A = 'VARIANT_A',
  VARIANT_B = 'VARIANT_B',
  VARIANT_C = 'VARIANT_C',
}

export interface CityLocationData {
  city: string;
  localizedCity: Message | null;
  locationFaqData: LocationFaqData;
}

export type CustomSlugPage = {
  name: string;
  slug: string;
};

export type InitialJobs = {
  jobsInPage: JobInterface[];
  totalJobs: number;
  numberOfJobsCreatedInLast14Days: number | null;
};

export type ExploreProps = {
  initialJobs: InitialJobs;
  initialFilters: JobSearchFiltersResponse;
  textualContent: {
    paragraph: RawDraftContentState;
    secondaryParagraph: RawDraftContentState;
    customSlugPages: CustomSlugPage[];
    faqData: Array<{ question: string; answer: string }>;
  };
  pageMetadata: {
    title: string;
    description: string;
  };
  cityLocationData: CityLocationData;
  hierarchicalLocation: HierarchicalLocation & {
    locationFaqData: LocationFaqData;
  };
  hierarchicalCategory: HierarchicalJobCategory;
  customSlug: {
    name: string;
    filter: ParsedUrlQuery;
  };
  queryLocationId: string | string[];
  queryCategoryId: string | string[];
  queryVariableData: SearchJobsQueryData | Record<string, never>;
  searchSuggestions: SearchSuggestionsData;
};

export interface ExploreBreadcrumbProps {
  slug: string;
  type: SlugTypes;
  hierarchicalLocationData: HierarchicalLocation;
  hierarchicalCategoryData: HierarchicalJobCategory;
}

export interface HierarchicalBreadcrumbData {
  id: string;
  name: string;
  url: string;
}

export interface BreadcrumbSchema {
  '@context': string;
  '@type': string;
  itemListElement: BreadcrumbSchemaItem[];
}

export interface BreadcrumbSchemaItem {
  '@type': string;
  position: number;
  name: string;
  item: string;
}

export interface SearchSuggestion {
  keyword: string;
  type: string;
  locationName?: string;
}

export interface PopularSearch {
  keyword: string;
  type: string;
}

export interface SearchSuggestionsData {
  pastSearches: SearchRouterQuery[];
  popularSearches: PopularSearch[];
}
