import {
  ArrowNextIcon,
  Greyscale,
  ScreenSize,
  SecondaryColor,
  Typography,
} from 'glints-aries';
import styled from 'styled-components';

export const DrawerContainer = styled.div`
  .drawer-background {
    background-color: ${SecondaryColor.actionblue};
  }
`;

export const NewFeatureBadgeContainer = styled.span`
  position: relative;
  top: -1em;
  font-size: xx-small;
  letter-spacing: 0px;
`;

export const Container = styled.div`
  padding-bottom: 150px;
  width: 100%;
  min-height: 100vh;
  background-color: ${SecondaryColor.actionblue};
  @media (min-width: ${ScreenSize.tablet}px) {
    padding-bottom: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 21px;
  padding: 25px 10px 20px 20px;
  cursor: pointer;
`;

export const PaddingTop = styled.div<{
  padding?: number;
}>`
  padding-top: ${({ padding }) => padding}px;
`;

export const SectionTitleWrapper = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${Greyscale.white};
  padding: 12px 10px;
  letter-spacing: 2px;
`;

export const TextWrapper = styled.div<{
  textTransform?: string;
}>`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: ${Greyscale.white};
  padding: 10px 20px;
  width: 100%;
  a {
    color: ${Greyscale.white};
    text-transform: uppercase;
  }
  &:hover {
    background-color: #0071a4;
  }
  svg {
    margin-left: 10px;
    font-size: 15px;
  }

  text-transform: ${({ textTransform }) => textTransform || 'none'};
`;

export const UpperCaseWrapper = styled.div`
  display: inline-block;
  text-transform: uppercase;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowRight = styled(ArrowNextIcon)`
  width: 12px;
  height: 12px;
`;

export const Divider = styled.div`
  background-color: ${Greyscale.white};
  height: 1px;
  margin: 10px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: regular;
    padding-left: 14px;
  }
`;

export const ProfilePictureWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin-left: 20px;
  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const ProfileText = styled(Typography.Paragraph).attrs({
  ellipsis: true,
})`
  color: ${Greyscale.white};
  margin: 0 15px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const BorderWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid ${Greyscale.white};

  svg {
    margin-left: 10px;
    font-size: 15px;
  }
`;
