import React from 'react';
import { merge } from 'lodash';
import Link from 'next/link';
import Router from 'next/router';
import { FormattedMessage } from 'react-intl';
import { createAction } from 'redux-actions';

import { CALL_API } from 'src/common/constants';
import Schemas from 'src/common/schemas';
import { Company, Job } from 'src/global/models';
import { Bookmark } from 'src/global/models/Bookmark';
import { ReduxThunkAction } from 'src/global/store';
import { CallAPIOptions, DstResponse } from 'src/middleware/api/interfaces';
import {
  BOOKMARK_MAP,
  BOOKMARK_NOTIFICATION_MAP,
  BOOKMARK_NOTIFICATION_TYPES,
  BOOKMARK_REDIRECT_QUERY_ID_KEY,
  BOOKMARK_TYPES,
} from 'src/modules/Bookmark/constants';
import { JobInterface } from 'src/modules/Opportunity/interface';

import { showCloseableNotificationWithType } from '../notifications';

export interface BookmarkPayload {
  type: BOOKMARK_TYPES;
  id: string;
  bookmark?: Bookmark;
}

const BOOKMARK_LIMIT = 100;

const getBookmarkAPIHooks = (
  notificationType: BOOKMARK_NOTIFICATION_TYPES,
  payload: BookmarkPayload
) => {
  const { type, id, bookmark } = payload;
  return {
    triggerAlert: {
      requestMsg: null as any,
      successMsg: BOOKMARK_NOTIFICATION_MAP[notificationType][type],
      failureMsg: (
        <FormattedMessage
          id="oops.something.went.wrong.please.try.again.later"
          defaultMessage="Oops something went wrong. Please try again later."
          tagName="span"
        />
      ),
    },
    requestPayload: { type, id },
    successPayload: { type, id, bookmark },
    failurePayload: { type, id },
  };
};

export const Actions = {
  FETCH_BOOKMARKS_REQUEST: 'glints/user/bookmarks/FETCH_BOOKMARKS_REQUEST',
  FETCH_BOOKMARKS_SUCCESS: 'glints/user/bookmarks/FETCH_BOOKMARKS_SUCCESS',
  FETCH_BOOKMARKS_FAILURE: 'glints/user/bookmarks/FETCH_BOOKMARKS_FAILURE',

  BOOKMARK_REQUEST: 'glints/user/bookmarks/BOOKMARK_REQUEST',
  BOOKMARK_SUCCESS: 'glints/user/bookmarks/BOOKMARK_SUCCESS',
  BOOKMARK_FAILURE: 'glints/user/bookmarks/BOOKMARK_FAILURE',

  UNBOOKMARK_REQUEST: 'glints/user/bookmarks/UNBOOKMARK_REQUEST',
  UNBOOKMARK_SUCCESS: 'glints/user/bookmarks/UNBOOKMARK_SUCCESS',
  UNBOOKMARK_FAILURE: 'glints/user/bookmarks/UNBOOKMARK_FAILURE',

  CLEAR_BOOKMARKS: 'glints/user/bookmarks/CLEAR_BOOKMARKS',
};

export const clearBookmarks = createAction(Actions.CLEAR_BOOKMARKS);

export function fetchMyBookmarks(
  options?: CallAPIOptions
): ReduxThunkAction<any> {
  return async (dispatch, getState) => {
    const authenticated = Boolean(getState().session.token);
    if (!authenticated) {
      return null;
    }

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.FETCH_BOOKMARKS_REQUEST,
          Actions.FETCH_BOOKMARKS_SUCCESS,
          Actions.FETCH_BOOKMARKS_FAILURE,
        ],
        endpoint: 'me/bookmarks',
        schema: Schemas.BOOKMARK_ARRAY,
        options: merge(options, {
          params: {
            limit: BOOKMARK_LIMIT,
          },
        }),
      },
    });
    return getState();
  };
}

export function bookmark(
  type: BOOKMARK_TYPES,
  bookmarkEntity: Company | Job | JobInterface,
  options: CallAPIOptions
): ReduxThunkAction<any> {
  const id = bookmarkEntity.id;
  const idKey = BOOKMARK_MAP[type].idKey;
  const redirectQueryIdKey = (BOOKMARK_REDIRECT_QUERY_ID_KEY as any)[type];
  // @ts-ignore
  return async (dispatch, getState) => {
    const authenticated = Boolean(getState().session.token);
    if (!authenticated) {
      return Router.push({
        pathname: '/login',
        query: {
          ...Router.query,
          next: Router.pathname,
          nextAs: Router.asPath,
          bookmark: id,
          ...(redirectQueryIdKey && { [redirectQueryIdKey]: id }),
        },
      });
    }
    const {
      user: { bookmarks },
    } = getState();
    const stateKey = BOOKMARK_MAP[type].reduxStateKey;
    if (bookmarks.value.length >= BOOKMARK_LIMIT) {
      return await dispatch(
        showCloseableNotificationWithType({
          message: (
            <FormattedMessage
              id="text-bookmark-max-limit-reached"
              defaultMessage="Unable to bookmark this job since you already have {count} bookmarked jobs. Please <link>manage your bookmark</link> and try again."
              values={{
                count: bookmarks[stateKey].value.length,
                link: (chunks: any) => (
                  <Link href="/opportunities/jobs/bookmarked">
                    <a>{chunks}</a>
                  </Link>
                ),
              }}
            />
          ),
          type: 'danger',
        })
      );
    }

    const bookmarkingState = bookmarks[stateKey].bookmarking;
    const isBookmarking = bookmarkingState.includes(id);
    /*
      Because the job page has two same bookmark buttons
      which might invoke bookmarkAfterLogin at the same time,
      and thus we use !isBookmarking to prevent 409(Conflict) error
    */
    if (!isBookmarking) {
      await dispatch({
        [CALL_API]: {
          types: [
            Actions.BOOKMARK_REQUEST,
            Actions.BOOKMARK_SUCCESS,
            Actions.BOOKMARK_FAILURE,
          ],
          endpoint: 'me/bookmarks',
          schema: Schemas.BOOKMARK,
          options: merge(options, {
            method: 'POST',
            data: {
              data: {
                [idKey]: id,
              },
            },
          }),
          hooks: getBookmarkAPIHooks(BOOKMARK_NOTIFICATION_TYPES.bookmarkSuc, {
            type,
            id,
          }),
        },
      });

      await dispatch(fetchMyBookmarks());
    }
  };
}

export type BookmarkResponse = DstResponse<
  any,
  BookmarkPayload,
  BookmarkPayload,
  BookmarkPayload
>;

export function unbookmark(
  type: BOOKMARK_TYPES,
  bookmark: Bookmark,
  options: CallAPIOptions
): ReduxThunkAction<any> {
  return async (dispatch) => {
    const { id } = bookmark;
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.UNBOOKMARK_REQUEST,
          Actions.UNBOOKMARK_SUCCESS,
          Actions.UNBOOKMARK_FAILURE,
        ],
        endpoint: `me/bookmarks/${id}`,
        options: merge(options, {
          method: 'DELETE',
        }),
        hooks: getBookmarkAPIHooks(BOOKMARK_NOTIFICATION_TYPES.unbookmarkSuc, {
          bookmark,
          type,
          id,
        }),
      },
    });
    await dispatch(fetchMyBookmarks());
  };
}
