import React, { useEffect, useRef, useState } from 'react';
import {
  SkeletonImageCircle,
  SkeletonImageSquare,
} from 'glints-aries/lib/@next';
import pick from 'lodash/pick';

import { skeletonImageSize } from 'src/common/constants';
import { windowProperties } from 'src/common/enums/windowProperties';
import { ThumborImageOptions } from 'src/common/getThumborImageURL';
import { useUrlUtils } from 'src/common/hooks/useUrlUtils';

import {
  ImgAttributeNames,
  ImgAttributes,
  ThumborImageOptionKeys,
  ThumborImageProps,
} from './interfaces';
import * as S from './ThumborImage.sc';
import { DefaultBreakpoints, scaleBreakpointsForHighDPR } from './utils';

export const ThumborImage: React.FC<
  React.PropsWithChildren<
    ThumborImageProps & {
      isURLImage?: boolean;
      isCircleImage: boolean;
      isEmbeddedImage?: boolean;
    }
  >
> = (props) => {
  const {
    breakpoints,
    aspectRatio,
    onError,
    lazy,
    eager,
    isURLImage,
    isCircleImage,
    isEmbeddedImage,
  } = props;
  const imgAttributes: ImgAttributes = pick(props, ImgAttributeNames);
  const thumborOptions: ThumborImageOptions = pick(
    props,
    ThumborImageOptionKeys
  );
  const { getThumborImageURL } = useUrlUtils();

  const intitialSrc = props.src;
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const _breakpoints = Array.isArray(breakpoints)
    ? breakpoints
    : scaleBreakpointsForHighDPR(breakpoints);

  const srcSet = !isEmbeddedImage
    ? _breakpoints
        .map((breakpoint) => {
          const url = isURLImage
            ? intitialSrc
            : getThumborImageURL({
                ...thumborOptions,
                size: {
                  height: aspectRatio ? Math.ceil(breakpoint / aspectRatio) : 0,
                  width: breakpoint,
                },
              });
          return `${url} ${breakpoint}w`;
        })
        .join(', ')
    : undefined;

  const src =
    isURLImage || isEmbeddedImage
      ? props.src
      : getThumborImageURL(thumborOptions);

  // SSRed image can be failed loaded before React's hydration,
  // so the attached onError handler will not be run.
  // to solve this, we add an error event listener in _document.tsx
  // to store an img error map in the window object,
  // and execute onError when necessary
  useEffect(
    function componentDidMount() {
      if (
        window[windowProperties.__IMAGE_ERROR__] &&
        window[windowProperties.__IMAGE_ERROR__][src] &&
        typeof onError === 'function'
      ) {
        onError();
      } else if (imageRef.current?.complete ?? false) {
        setImageLoaded(true);
      }
    },
    [onError, src]
  );

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const imageSize = imgAttributes.sizes ?? 'auto';

  const selectedSkeletonImage = (isCircleImage: boolean) => {
    if (isCircleImage) {
      return <SkeletonImageCircle size={skeletonImageSize} />;
    }
    return (
      <SkeletonImageSquare
        width={skeletonImageSize}
        height={skeletonImageSize}
      />
    );
  };

  return (
    <>
      {!imageLoaded && selectedSkeletonImage(isCircleImage)}
      <S.CustomImage
        {...imgAttributes}
        ref={imageRef}
        src={src}
        srcSet={srcSet}
        width={imgAttributes.width ?? imageSize}
        height={imgAttributes.height ?? imageSize}
        loading={eager ? 'eager' : lazy ? 'lazy' : undefined}
        onError={onError}
        onLoad={handleImageLoad}
        isLoaded={imageLoaded}
      />
    </>
  );
};

ThumborImage.defaultProps = {
  breakpoints: DefaultBreakpoints,
  lazy: true,
};
