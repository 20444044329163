import React from 'react';
import NoSSR from '@mpth/react-no-ssr';
import { CloseIcon, Greyscale } from 'glints-aries';
import { useDispatch, useSelector } from 'react-redux';

import { closeBasedSidebar } from 'src/actions/app';
import useConfig from 'src/common/hooks/useConfig';
import { EBreakpoints, Media } from 'src/media';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import { getFeatures } from 'src/modules/Unleash/Selectors';
import { getBasedSidebarOpen } from 'src/selectors/app';

import makeMenuItems from '../../MenuItems';
import { CustomDrawer } from './Navigation';
import * as S from './NavigationComponents.sc';
import { NavigationItems } from './NavigationItems';
import { MenuBaseItemType } from './types';

/**
 * ? Why do we need to separate the navigation into two different components?
 *
 * 1. @component Navigation:
 *  Handles navigation for other valid domains, such as `.com/id`, `.com/vn`, `.com/sg`, and `.com.my`.
 * 2. @component BasedNavigation**:
 *  Specifically handles navigation for the `.com` domain.
 *
 * Context:
 * When refactoring Unleash to use a GraphQL endpoint, we need to pass the @instance apolloClient
 * to dispatch the @function logout(). This is addressed in the task
 * @link (https://glints.atlassian.net/browse/GM-7944).
 *
 * !!! Issue in `.com` domain (Mobile Version):
 * In the `MainContainer`, `<Navigation>` acts as the parent component and wraps all child components.
 * However, this causes an issue in the mobile version of the `.com` domain:
 *
 * **Error**: `Could not find "client" in the context or passed in as an option`
 */

const BasedNavigation = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector(getIsAuthenticated);
  const config = useConfig();

  const features = useSelector(getFeatures);

  const basedSidebarOpen = useSelector(getBasedSidebarOpen);
  const handleCloseBasedSidebar = () => {
    dispatch(closeBasedSidebar());
  };

  const menuItems = React.useMemo(() => {
    return makeMenuItems(config, features, authenticated, true)() as
      | MenuBaseItemType
      | MenuBaseItemType[];
  }, [config, features, authenticated]);

  const countItems = React.useCallback(() => {
    if (!authenticated) {
      if (Array?.isArray(menuItems)) {
        return menuItems.filter((item) => !item?.requiresAuth);
      }
    }
    return menuItems;
  }, [authenticated, menuItems]);

  const filterMenuItems = countItems();

  const normalizedFilterMenuItems =
    filterMenuItems === undefined
      ? undefined
      : Array.isArray(filterMenuItems)
      ? filterMenuItems
      : [filterMenuItems];

  return (
    <S.DrawerContainer>
      <NoSSR>
        <Media lessThan={EBreakpoints.desktopS}>
          <CustomDrawer
            isOpen={basedSidebarOpen}
            onClose={handleCloseBasedSidebar}
          >
            <S.Container>
              <S.IconWrapper>
                <span
                  role="button"
                  aria-label="Close"
                  title="Click to close"
                  onClick={handleCloseBasedSidebar}
                >
                  <CloseIcon color={Greyscale.white} />
                </span>
              </S.IconWrapper>
              {normalizedFilterMenuItems && (
                <NavigationItems
                  handleCloseDrawer={handleCloseBasedSidebar}
                  menuItems={normalizedFilterMenuItems}
                />
              )}
            </S.Container>
          </CustomDrawer>
        </Media>
      </NoSSR>
    </S.DrawerContainer>
  );
};

export default BasedNavigation;
