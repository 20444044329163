import { snakeCase } from 'lodash';

export class TrackingUtilHelpers {
  static parseStringToObject(input: string): Record<string, string> {
    if (!input) return {};

    return input.split(',').reduce(
      (acc, curr) => {
        const [key, value] = curr.split(':');
        return { ...acc, [key]: value };
      },
      {} as Record<string, string>
    );
  }

  /**
   * Sanitize the key of payload to follow snakeCase and remove undefined values
   * For example:
   * {
   *   userAge: 18,
   *   userName: 'Yayo',
   *   userLastName: undefined,
   *   userLocation: null,
   * }
   *
   * Will be sanitized to:
   *
   * {
   *   user_age: 18,
   *   user_name: 'Yayo',
   *   user_location: null,
   * }
   * @param payload
   */
  static sanitizePayload(payload?: Record<string, any>): Record<string, any> {
    if (!payload) return {};
    const sanitizedPayload: Record<string, any> = {};
    for (const [key, value] of Object.entries(payload)) {
      if (typeof value !== 'undefined') {
        sanitizedPayload[snakeCase(key)] = value;
      }
    }
    return sanitizedPayload;
  }
}
