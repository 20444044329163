import React, { memo, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'src/modules/Session/Actions';
import { getIsTokenNotValid } from 'src/modules/Session/Selectors';

const _LogoutUserWhenTokenIsNotValid: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch();
  const isTokenNotValid = useSelector(getIsTokenNotValid);
  const apolloClient = useApolloClient();

  useEffect(
    function listenToIsTokenValidChange() {
      if (isTokenNotValid) {
        dispatch(logout(apolloClient));
      }
    },
    [dispatch, isTokenNotValid, apolloClient]
  );

  return null;
};

export const LogoutUserWhenTokenIsNotValid = memo(
  _LogoutUserWhenTokenIsNotValid
);
