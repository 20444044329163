import { Products } from 'src/common/enums';
import { SignUpOrLoginType } from 'src/common/tracking/constants';
import { pushGTMEvent } from 'src/common/utils/google-tag-manager';
import { getJobCategoryData } from 'src/common/utils/hierarchicalJobCategoryHelpers';
import { JobStatus, JobType } from 'src/global/models/Job';
import { JobRecommendationType } from 'src/modules/Opportunity/interface';
import { JobCategory } from 'src/modules/Profile/graphql/jobCategoriesAndTitles';

import { TrackingUtilHelpers } from './trackingUtilHelpers';

const trackEvent = (
  eventName: string,
  payload: Record<string, any>,
  userPayload?: Record<string, any>
) => {
  pushGTMEvent('glints/GoogleTagManager/GENERIC_ON_TRACK_EVENT', {
    genericEventName: eventName,
    genericPayload: TrackingUtilHelpers.sanitizePayload(payload),
    genericUserPayload: TrackingUtilHelpers.sanitizePayload(userPayload),
  });
};

export const trackUserSignUpOrLogin = (data: {
  isSignUp: boolean;
  type: SignUpOrLoginType;
  source?: 'Google one tap';
}) => {
  pushGTMEvent('glints/GoogleTagManager/SIGN_UP_OR_LOGIN_SUCCESS', {
    payload: TrackingUtilHelpers.sanitizePayload({
      type: data.type,
      source: data.source,
      isSignUp: data.isSignUp,
      productSource: Products.Marketplace,
    }),
  });
};

export const trackOnSourcedJobDetailsPageViewed = (data: {
  userId?: string;
  companyName: string;
  minSalary?: number;
  maxSalary?: number;
  jobId: string;
  jobCategory?: JobCategory;
  jobType: JobType;
  isSourced: boolean;
  isExternal: boolean;
  hasSimilarJobs: boolean;
  similarJobsCount: number;
}) => {
  const jobCategoryData =
    data.jobCategory && getJobCategoryData(data.jobCategory);
  return trackEvent('job_details_page_viewed', {
    glintsUserId: data.userId,
    jobId: data.jobId,
    categoryName: jobCategoryData?.categoryName,
    subCategoryName: jobCategoryData?.subCategoryName,
    roleName: jobCategoryData?.roleName,
    isSourced: data.isSourced,
    companyName: data.companyName,
    jobType: data.jobType,
    hasSimilarJobs: data.hasSimilarJobs,
    similarJobsCount: data.similarJobsCount,
    isSalaryShown: Boolean(data.minSalary) || Boolean(data.maxSalary),
  });
};

export const trackOnForYouPageViewed = (data: {
  expRecommendation: string;
  hasFYPResults: boolean;
  experimentationInfo: string;
  firstFetchJobCount: number;
}) => {
  const experimentationInfoData = TrackingUtilHelpers.parseStringToObject(
    data.experimentationInfo
  );

  const userPayload = {
    ...experimentationInfoData,
    expRecommendation: data.expRecommendation,
  };

  return trackEvent(
    'page_viewed',
    {
      hasFYPResults: data.hasFYPResults,
      expRecommendation: data.expRecommendation,
      firstFetchJobCount: data.firstFetchJobCount,
    },
    userPayload
  );
};

export const trackExternalLinkContinued = (data: {
  userId: string;
  companyName: string;
  minSalary?: number;
  maxSalary?: number;
  jobId: string;
  jobCategory?: JobCategory;
  jobType: JobType;
  isSourced: boolean;
  isExternal: boolean;
}) => {
  const jobCategoryData =
    data.jobCategory && getJobCategoryData(data.jobCategory);
  trackEvent('external_link_continued', {
    glintsUserId: data.userId,
    jobId: data.jobId,
    categoryName: jobCategoryData?.categoryName,
    subCategoryName: jobCategoryData?.subCategoryName,
    roleName: jobCategoryData?.roleName,
    isSourced: data.isSourced,
    companyName: data.companyName,
    isExternal: data.isExternal,
    jobType: data.jobType,
    isSalaryShown: Boolean(data.minSalary) || Boolean(data.maxSalary),
  });
};

export const trackApplyJobClicked = (data: {
  userId?: string;
  companyName: string;
  minSalary?: number;
  maxSalary?: number;
  jobId: string;
  jobCategory?: JobCategory;
  jobType: JobType;
  isSourced: boolean;
  isExternal: boolean;
}) => {
  const jobCategoryData =
    data.jobCategory && getJobCategoryData(data.jobCategory);

  trackEvent('apply_job_button_clicked', {
    glintsUserId: data.userId,
    jobId: data.jobId,
    categoryName: jobCategoryData?.categoryName,
    subCategoryName: jobCategoryData?.subCategoryName,
    roleName: jobCategoryData?.roleName,
    isSourced: data.isSourced,
    companyName: data.companyName,
    jobType: data.jobType,
    isExternal: data.isExternal,
    isSalaryShown: Boolean(data.minSalary) || Boolean(data.maxSalary),
  });
};

export const trackMoreJobClicked = (data: {
  jobId: string;
  isSourced?: boolean;
  hostJobLocale: string | undefined;
  hostJobCategory: string | undefined;
  hostJobStatus: JobStatus | null;
  hostJobRole: string | undefined;
  companyNameOfHost: string;
  type: JobRecommendationType;
}) => {
  const eventName =
    data.type === JobRecommendationType.SIMILAR
      ? 'click_similar_jobs_on_job_detail_pages'
      : 'click_other_jobs_from_same_company_on_job_detail_pages';
  trackEvent(eventName, {
    jobId: data.jobId,
    hostJobRole: data.hostJobRole,
    hostJobStatus: data.hostJobStatus,
    hostJobLocale: data.hostJobLocale,
    hostJobIsSourced: data.isSourced ?? false,
    hostJobCategory: data.hostJobCategory,
    companyNameOfHost: data.companyNameOfHost,
  });
};

export const trackSocialButtonClicked = (data: {
  type: 'google' | 'facebook' | 'email' | 'linkedIn';
  page: 'signup' | 'login';
}) => {
  trackEvent('social_button_clicked', {
    type: data.type,
    page: data.page,
    platform: 'web',
  });
};
