import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  getIsLoggingIn,
  getIsSigningUp,
  getLoginError,
  getSignUpError,
  getUserMeta,
} from 'src/modules/Session/Selectors';
import { getIsShowLoginPopupEnabled } from 'src/modules/Unleash/Selectors';

import { closeLoginSignUpModal } from '../../actions/app';
import { updateMeta } from '../../actions/user/me';
import authenticate from '../../common/authenticate';
import {
  clearCreateUserError,
  clearReceiveTokenError,
  login,
} from '../../modules/Session/Actions';
import { getUser } from '../../selectors/user';
import LoginSignUpModal from './LoginModal';

const mapStateToProps = state => ({
  isLoadingLogin: getIsLoggingIn(state),
  isLoadingSignup: getIsSigningUp(state),
  isFetchingMe: state.user.me.fetching,
  isVisible: state.app.eventsLoginSignUpModalOpen,
  source: state.app.source,
  eventsLoginSignUpModalAfterLoginCallback:
    state.app.eventsLoginSignUpModalAfterLoginCallback,
  eventsLoginSignUpModalAfterSignUpClicked:
    state.app.eventsLoginSignUpModalAfterSignUpClicked,
  loginError: getLoginError(state),
  signUpError: getSignUpError(state),
  me: getUser(state),
  userMeta: getUserMeta(state),
  isShowLoginPopupEnabled: getIsShowLoginPopupEnabled(state),
});

const mapDispatchToProps = {
  clearLoginError: clearReceiveTokenError,
  clearSignUpError: clearCreateUserError,
  login,
  onClose: closeLoginSignUpModal,
  updateMeta,
};

const ConnectedLoginSignupModal = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginSignUpModal);

export default authenticate(ConnectedLoginSignupModal, true);
