import { ApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';

import { COOKIE_DEVICE_ID, PLATFORM_DST } from 'src/common/constants';
import { tryQuery } from 'src/common/graphql/graphqlHelper';
import isServer from 'src/common/isServer';
import { ReduxThunkAction } from 'src/global/store';
import { getCountryWithSGFallback } from 'src/modules/ClientConfig/Selectors';

import { ApiV2ClientName } from '../ApolloWrapper/constants';
import { getDeviceIdFromCookie } from '../ClientConfig/helpers';
import { getIsAuthenticated } from '../Session/Selectors';
import {
  AuthenticatedFeatureFlags,
  UnauthenticatedFeatureFlags,
} from './constants';
import {
  GetEnabledFeatureFlagsResult,
  queryGetEnabledFeatureFlags,
} from './graphql';

export const Actions = {
  FETCH_FEATURES_REQUEST: 'glints/unleash/FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_SUCCESS: 'glints/unleash/FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_FAILURE: 'glints/unleash/FETCH_FEATURES_FAILURE',
};

export function fetchFeatures(
  apolloClient?: ApolloClient<object>
): ReduxThunkAction<any> {
  return async (dispatch, getState) => {
    if (!apolloClient) {
      console.error('ApolloClient is undefined. Cannot fetch features.');
      dispatch({
        type: Actions.FETCH_FEATURES_FAILURE,
        error: true,
        payload: 'ApolloClient is required but not provided',
      });
      return;
    }
    const state = getState();
    const currentCountry = getCountryWithSGFallback(state);
    const isLoggedIn = getIsAuthenticated(state);

    const selectedUnleashFeatureFlagsList = isLoggedIn
      ? AuthenticatedFeatureFlags
      : UnauthenticatedFeatureFlags;

    /**
     *  We cannot get the cookie in server-side by using function from
     *  @package js-cookie
     *  Otherwise, we can get cookies from client-side, so we need to check if isServer
     */
    const deviceId = isServer
      ? getDeviceIdFromCookie(state.session.cookie)
      : Cookies.get(COOKIE_DEVICE_ID);

    dispatch({ type: Actions.FETCH_FEATURES_REQUEST });

    if (!isServer) {
      await apolloClient.clearStore();
    }

    const { response, error } = await tryQuery<GetEnabledFeatureFlagsResult>({
      apolloClient,
      retries: 3,
      query: queryGetEnabledFeatureFlags,
      variables: {
        deviceId,
        platform: PLATFORM_DST,
        featureFlagNames: selectedUnleashFeatureFlagsList,
      },
      fetchPolicy: 'network-only',
      context: {
        'x-glints-country-code': currentCountry,
        'x-glints-device-id': deviceId,
        clientName: ApiV2ClientName,
      },
    });

    if (error) {
      console.error('FETCH_FEATURES_FAILURE with error:', {
        error,
        graphQLErrors: error.graphQLErrors,
        networkError: error.networkError,
        message: error.message,
        result: error.networkError?.result,
        errors: error.networkError?.result?.errors,
      });

      dispatch({
        type: Actions.FETCH_FEATURES_FAILURE,
        error: true,
        payload:
          error instanceof Error ? error.message : 'An unknown error occurred',
      });

      throw error;
    }

    dispatch({
      type: Actions.FETCH_FEATURES_SUCCESS,
      payload: response?.getEnabledFeatureFlags ?? [],
    });
  };
}
