import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { BroadcastChannel } from 'broadcast-channel';
import { useDispatch, useSelector } from 'react-redux';

import { CHANNEL_NAME_LOGOUT } from 'src/common/broadcastChannels';
import { cleanDataWhenLogout } from 'src/modules/Session/Actions';

import { getIsAuthenticated } from '../Selectors';

export const LogoutListener: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsAuthenticated);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (isLoggedIn) {
      const channel = new BroadcastChannel(CHANNEL_NAME_LOGOUT);
      channel.onmessage = () => dispatch(cleanDataWhenLogout(apolloClient));
      return () => channel.close();
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [isLoggedIn, dispatch, apolloClient]);
  return null;
};
