import React, { useCallback, useMemo } from 'react';
import NoSSR from '@mpth/react-no-ssr';
import { ArrowRoundForwardIcon, CloseIcon, Drawer } from 'glints-aries';
import { Greyscale } from 'glints-aries/lib/Utils/Colors';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { closeSidebar } from 'src/actions/app';
import authenticate from 'src/common/authenticate';
import { CountryCodes } from 'src/common/enums';
import { pageCategoryMapper } from 'src/common/helpers';
import useConfig from 'src/common/hooks/useConfig';
import { pushGTMEvent } from 'src/common/utils/google-tag-manager';
import LanguageSwitcher from 'src/components/GlintsLanguageSwitcher/LanguageSwitcher';
import EmployersLink from 'src/components/links/EmployersLink/EmployersLink';
import { EBreakpoints, Media } from 'src/media';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import { getFeatures } from 'src/modules/Unleash/Selectors';
import { getSidebarOpen } from 'src/selectors/app';
import { getAvailableLanguages } from 'src/selectors/locale';

import makeMenuItems from '../../MenuItems';
import { employerSiteLinkMessage } from '../../messages';
import { AuthenticationMenuItemsSection } from './AuthenticationMenuItemsSection';
import * as S from './NavigationComponents.sc';
import { NavigationItems } from './NavigationItems';
import { MenuBaseItemType } from './types';

const NavigationComponent: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const router = useRouter();

  const sidebarOpen = useSelector(getSidebarOpen);
  const availableLanguages = useSelector(getAvailableLanguages);
  const config = useConfig();
  const country = config?.COUNTRY;
  const features = useSelector(getFeatures);
  const authenticated = useSelector(getIsAuthenticated);

  const handleCloseSidebar = useCallback(() => {
    dispatch(closeSidebar());
  }, [dispatch]);

  const menuItems = useMemo(() => {
    return makeMenuItems(config, features, authenticated, true)() as
      | MenuBaseItemType
      | MenuBaseItemType[];
  }, [config, features, authenticated]);

  const countItems = useCallback(() => {
    if (!authenticated) {
      if (Array?.isArray(menuItems)) {
        return menuItems.filter((item) => !item?.requiresAuth);
      }
    }
    return menuItems;
  }, [authenticated, menuItems]);

  React.useEffect(() => {
    document.body.style.overflow = sidebarOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [sidebarOpen]);

  const renderLanguageSwitcher = () => {
    if (availableLanguages.length <= 1) return null;

    return (
      <LanguageSwitcher
        fontSize={16}
        iconDefaultColor={Greyscale.white}
        textColor={Greyscale.white}
        fontWeight={600}
        languages={availableLanguages}
        showFullWidth={true}
        showHoverLine={false}
      />
    );
  };

  const renderSidebar = () => {
    const pageCategory = pageCategoryMapper(router.pathname);
    const filterMenuItems = countItems();

    const normalizedFilterMenuItems =
      filterMenuItems === undefined
        ? undefined
        : Array.isArray(filterMenuItems)
        ? filterMenuItems
        : [filterMenuItems];

    const handleLinkOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      pushGTMEvent('glints/GoogleTagManager/DOWNLOAD_APP_BUTTON_CLICKED', {
        payload: {
          placement: 'header',
          buttonCta: 'get glints app',
          pageCategory,
        },
      });

      window.open(event.currentTarget.href, '_blank', 'noopener,noreferrer');
    };

    return (
      <S.Container>
        <S.IconWrapper>
          <span
            role="button"
            aria-label="Close"
            title="Click to close"
            onClick={handleCloseSidebar}
          >
            <CloseIcon color={Greyscale.white} />
          </span>
        </S.IconWrapper>
        {country === CountryCodes.ID && (
          <>
            <S.PaddingTop padding={15}>
              <a
                href="https://dynamic-link.glints.com/page/XJbp"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkOnClick}
              >
                <S.TextWrapper>
                  <S.BorderWrapper>
                    <S.UpperCaseWrapper>
                      <FormattedMessage
                        id="text-get-glints-app"
                        defaultMessage="Get Glints App"
                        tagName="span"
                      />
                    </S.UpperCaseWrapper>
                  </S.BorderWrapper>
                </S.TextWrapper>
              </a>
            </S.PaddingTop>
            <S.Divider />
          </>
        )}
        {country && <AuthenticationMenuItemsSection />}
        {normalizedFilterMenuItems && (
          <NavigationItems
            handleCloseDrawer={handleCloseSidebar}
            menuItems={normalizedFilterMenuItems}
          />
        )}
        {country && (
          <>
            <S.Divider />
            <EmployersLink>
              <S.TextWrapper>
                <S.UpperCaseWrapper>
                  <FormattedMessage
                    {...employerSiteLinkMessage}
                    tagName="span"
                  />
                </S.UpperCaseWrapper>
                <ArrowRoundForwardIcon color={Greyscale.white} />
              </S.TextWrapper>
            </EmployersLink>
          </>
        )}
        {renderLanguageSwitcher()}
      </S.Container>
    );
  };

  return (
    <S.DrawerContainer>
      <NoSSR>
        <Media lessThan={EBreakpoints.desktopS}>
          <CustomDrawer isOpen={sidebarOpen} onClose={handleCloseSidebar}>
            {renderSidebar()}
          </CustomDrawer>
        </Media>
      </NoSSR>
    </S.DrawerContainer>
  );
});

/**
 * Not sure why we can't put this styled-component inside NavigationComponents.sc.ts
 * React will complain about this error, it cause the local env crash when opening this mobile menu
 * ! Warning: React.jsx: type is invalid -- expected a string (for built-in components)
 * or a class/function (for composite components) but got: undefined.
 * You likely forgot to export your component from the file it's defined in, or you might have mixed up default and named imports.
 */
export const CustomDrawer = styled(Drawer).attrs({
  className: 'drawer-background',
})`
  scrollbar-width: thin;
`;

NavigationComponent.displayName = 'NavigationComponent';
export default authenticate(NavigationComponent, true);
