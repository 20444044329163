import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import NoSSR from '@mpth/react-no-ssr';
import { AlertType } from 'glints-aries/lib/General/Alert/Alert';
import { useRouter } from 'next/router';
import Favicon from 'react-favicon';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import {
  GTMSetUser,
  GTMSetUserWithCallbackAction,
} from 'src/actions/googleTagManager';
import { LogoutListener } from 'src/modules/Session/RenderLessComponents/LogoutListener';
import { LogoutUserWhenTokenIsNotValid } from 'src/modules/Session/RenderLessComponents/LogoutUserWhenTokenIsNotValid';
import { getUserId } from 'src/selectors/user';

import { dismissCloseableNotification } from './actions/notifications';
import { getIsTokenScoped } from './common/helpers';
import MarketingParams from './components/MarketingParams';
import { Alert, AlertPositionType } from './MainLayout.sc';
import { getConfig } from './modules/ClientConfig';
import { fetchGeneralConfig } from './modules/GeneralConfig/Actions';
import MainContainer from './modules/MainContainer/MainContainer';
import { logout } from './modules/Session/Actions';
import { getScopedTokenActionCompleted } from './modules/Session/Selectors';
import {
  getNotificationMessage,
  getNotificationPosition,
  getNotificationState,
  getNotificationType,
} from './selectors/notifications';

interface MainLayoutProps {
  children: React.ReactNode;
  faviconURL?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  faviconURL = 'images/favicon.ico',
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const notificationOpen = useSelector(getNotificationState);
  const message = useSelector(getNotificationMessage) as string;
  const notificationType = useSelector(getNotificationType) as AlertType;
  const rawPosition = useSelector(getNotificationPosition);
  const notificationPosition: AlertPositionType =
    rawPosition === 'top' || rawPosition === 'top-right' ? rawPosition : 'top';

  const autoCloseInterval = useSelector(
    (state: any) => state.notifications.closeable.autoCloseInterval
  );
  const scopedTokenActionCompleted = useSelector(getScopedTokenActionCompleted);
  const defaultAutoCloseInterval = useSelector(
    (state: any) => getConfig(state)?.DEFAULT_AUTO_CLOSE_INTERVAL ?? 3000
  );
  const userId = useSelector(getUserId);

  useEffect(() => {
    dispatch(fetchGeneralConfig());
    dispatch(GTMSetUser());
  }, [dispatch]);

  useEffect(() => {
    const checkScopedToken = async () => {
      if (scopedTokenActionCompleted) {
        const isScoped = await getIsTokenScoped();
        if (isScoped) {
          setTimeout(() => dispatch(logout(apolloClient)), 3000);
        }
      }
    };
    checkScopedToken();
  }, [scopedTokenActionCompleted, dispatch, apolloClient]);

  useEffect(() => {
    const { utm_campaign, utm_referrer, utm_medium, utm_source } = router.query;

    if (userId === null) {
      dispatch(
        GTMSetUser({
          utm_campaign,
          utm_referrer,
          utm_medium,
          utm_source,
        })
      );
    } else {
      dispatch(
        GTMSetUserWithCallbackAction({
          utm_campaign,
          utm_referrer,
          utm_medium,
          utm_source,
        })
      );
    }
  }, [userId, router.query, dispatch]);

  return (
    <div>
      <Favicon url={faviconURL} />
      <NoSSR>
        <MarketingParams />
        <ToastContainer
          hideProgressBar={true}
          position="bottom-left"
          closeButton={false}
          draggable={false}
          icon={false}
          newestOnTop={true}
        />
        <LogoutListener />
        <LogoutUserWhenTokenIsNotValid />
        <Alert
          message={message}
          position={notificationPosition}
          isOpen={notificationOpen}
          onClose={() => dispatch(dismissCloseableNotification())}
          type={notificationType}
          autoClose={autoCloseInterval || defaultAutoCloseInterval}
        />
      </NoSSR>
      <MainContainer>{children}</MainContainer>
    </div>
  );
};

export default MainLayout;
